














































































































$gray-100: #f7f9fb !default;
$gray-200: #ebecf0 !default;
$gray-300: #c5c8d4 !default;
$gray-400: #999fb3 !default;
$gray-500: #7b839e !default;
$gray-600: #4c5267 !default;
$gray-700: #252f3f !default;
$f-grid-gap: 1.66667rem !default;
$container-padding: 4vw !default;
$container: 1140px !default;
$container-sm: 555px !default;
$container-md: 750px !default;
$container-lg: 945px !default;
$border-radius: 3px;

#colophon {
  color: $gray-400;
  background: $gray-700;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-200;
  }

  a {
    &:not(.button) {
      color: inherit;

      &:hover {
        color: $gray-200;
      }
    }
  }
}

.footer-top {
  padding-top: 3.33333em;
  padding-bottom: 1.11111em;
  border-bottom: 1px solid rgba($gray-600, 0.4);
}

.footer-bottom {
  padding-top: 1.66667em;
  padding-bottom: 1.66667em;
  text-align: center;
}

.widget {
  margin-bottom: 2.5em;
  font-size: 0.88889em;
  line-height: 1.5;
}

.widget-image {
  display: block;
  margin-bottom: 0.75em;
}

.widget-title {
  margin: 0 0 1.2em;
  font-size: 1.125em;
}

.widget-nav,
.footer-nav {
  a {
    &:not(.button) {
      text-decoration: none;
    }
  }
}

.widget-nav {
  .menu-item {
    margin-bottom: 0.75em;
  }

  .icon {
    & + :not(.order-first) {
      margin-left: 0.75em;
    }
  }
}

.footer-nav {
  .menu-item {
    display: inline-block;
    margin: 0 0.75em 0.25em;
  }
}

.site-info {
  font-size: 0.77778em;
  line-height: 1.5;

  &:not(:first-child) {
    margin-top: 1em;
  }
}
.version {
  font-size: 0.77778em;
  line-height: 1.5;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 481px) {
  .footer-top {
    .f-cell {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .widget-text {
      &:first-child {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 701px) {
  .footer-top {
    .widget-text {
      &:first-child {
        & ~ .f-cell {
          -ms-flex: 0 0 33.333%;
          flex: 0 0 33.333%;
          max-width: 33.333%;
        }
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .footer-top {
    .f-grid {
      -ms-flex-pack: center;
      justify-content: center;
    }

    .f-cell,
    .widget-text:first-child,
    .widget-text:first-child ~ .f-cell {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
.f-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -$f-grid-gap / 2;
  margin-left: -$f-grid-gap / 2;
}

.f-cell {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-right: $f-grid-gap / 2;
  padding-left: $f-grid-gap / 2;
}

@media only screen and (max-width: 750px) {
  .f-grid {
    margin-right: -$container-padding / 2;
    margin-left: -$container-padding / 2;
  }

  .f-cell {
    padding-right: $container-padding / 2;
    padding-left: $container-padding / 2;
  }
}

@media only screen and (min-width: 901px) {
  .footer-top {
    .f-grid {
      -ms-flex-pack: center;
      justify-content: center;
    }

    .f-cell,
    .widget-text:first-child,
    .widget-text:first-child ~ .f-cell {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.outer {
  padding: 3.33333em $container-padding 2.5em;
}

@media only screen and (min-width: 601px) {
  .outer {
    padding-top: 4.16667em;
    padding-bottom: 3.33333em;
  }
}

.inner {
  max-width: $container;
  margin-right: auto;
  margin-left: auto;
}

.inner-large {
  max-width: $container-lg;
  margin-right: auto;
  margin-left: auto;
}

.inner-medium {
  max-width: $container-md;
  margin-right: auto;
  margin-left: auto;
}

.inner-small {
  max-width: $container-sm;
  margin-right: auto;
  margin-left: auto;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-item {
  font-size: 0.88889rem;
  line-height: 1.5;
}

.has-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -ms-flex-align: center;
  -ms-flex-pack: center;

  .icon {
    & + .order-first {
      margin-right: 0.5em;
    }

    & + :not(.order-first) {
      margin-left: 0.5em;
    }
  }
}
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.button {
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 0px;
  padding: 0.5em 1.875em;
  color: #fff;
  font-weight: bold;
  font-size: 0.88889em;
  line-height: 1.5;
  text-decoration: none;
  background: #ea5455;
  border: 2px solid #ea5455;
  border-radius: $border-radius;
  cursor: pointer;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
  -ms-flex-align: center;
  -ms-flex-pack: center;

  .font-fira-sans & {
    font-weight: 600;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    outline: 0;
    opacity: 0.8;
  }

  &.large {
    padding: 0.625em 2.5em;
  }

  &.secondary {
    color: #ea5455;
    background: transparent;
  }
}
